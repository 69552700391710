import React from "react";
import Layout from "./../components/Layout";
import "./contact.scss";

const ContactPage = () => {
  return (
    <Layout>
      <div className="container">
        <p>contact-page</p>
      </div>
    </Layout>
  );
};

export default ContactPage;
